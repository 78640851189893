.landing-page-header > div {
  justify-content: flex-end;
}

.landing-page-popular,
.landing-page-top-categories {
  flex-direction: column;
  align-items: flex-start;
  color: var(--sub-title-color);
}

.landing-page-popular > div,
.landing-page-top-categories > div {
  flex-wrap: wrap;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .landing-page-popular,
  .landing-page-top-categories {
    margin-top: 2.2rem;
  }
}

@media screen and (max-width: 400px) {
  .landing-page-content {
    padding: 2rem 5px 1rem;
  }

  .landing-page-top-categories {
    margin-bottom: 5rem;
  }

  .landing-page-popular > div,
  .landing-page-top-categories > div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: scroll;
  }

  .landing-page-popular > div::-webkit-scrollbar,
  .landing-page-top-categories > div::-webkit-scrollbar {
    display: none;
  }
}
