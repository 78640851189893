.backdrop {
  backdrop-filter: blur(6px);
}

.rules-container {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--title-color);
}

.rules-container button {
  width: 100%;
  margin: 0;
}

.rules-container > div {
  width: 35rem;
  background-color: var(--rule-modal-bg);
  padding: 2rem 1.5rem;
  border-radius: 15px;
  position: relative;
}

.rules-container .close-btn {
  top: 1rem;
  right: 1rem;
  padding: 8px 10px;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
}

.rules-container .close-btn:hover {
  background-color: var(--btn-hover);
}

.rules-text {
  padding: 5px 5px 5px 10px;
  margin: 1rem auto;
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.7rem;
  width: 100%;
}

.rules-text li {
  font-size: 1.1rem;
}
