.signin-modal {
  z-index: 4;
}

.signin-modal h1 {
  margin: 0 0 1rem;
}

.signin-modal .close-modal-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.create-account-btn h2 {
  font-size: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.create-account-btn:hover {
  cursor: pointer;
}

.signin-modal form {
  text-align: center;
}

.signin-modal span {
  color: var(--primary-color);
  margin-left: 1rem;
}

.password-input-field input:focus {
  outline: none;
}

.section-break-line {
  width: 50%;
  margin: 6px 0 1.1rem;
  border: none;
  border-bottom: 1px solid var(--primary-color);
}

.google-login,
.facebook-login {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  width: 100%;
}

.signin-modal .password-reset {
  color: var(--text-light);
}

.signin-modal .password-reset span {
  cursor: pointer;
}

.password-input-field i:nth-child(3) {
  left: 100%;
  transform: translate(-100%, 0);
  cursor: pointer;
}

@media screen and (max-width: 400px) {
  .signin-modal,
  .signin-modal form {
    width: 100%;
  }
}
