.nav-bar-top {
  display: none;
}

@media screen and (max-width: 768px) {
  .nav-bar-top {
    width: 100%;
    height: 3.5rem;
    padding: 8px;
    background-color: var(--nav-bar-bg);
    color: var(--text-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0rem;
  }

  .nav-bar-top > a img {
    height: 2rem;
  }

  .user-avatar {
    position: relative;
    margin-left: 1rem;
    cursor: pointer;
  }

  .avatar {
    width: 2rem;
    height: 2rem;
  }

  .avatar img {
    width: 2rem;
    height: 2rem;
  }

  .login-btn-mobile button {
    margin: 0 5px;
  }

  .active-button {
    outline: 1px solid var(--text-white);
  }

  .user-menu {
    position: absolute;
    top: 2.7rem;
    right: -10px;
    background-color: var(--nav-bar-bg);
    padding: 1rem;
    border-radius: 4px;
    width: 8rem;
    z-index: 2;
  }

  .user-menu button {
    color: var(--text-white);
    width: 6rem;
    margin: 5px 0;
  }

  .user-menu button:hover {
    outline: 1px solid var(--text-white);
    color: var(--text-white);
  }
}
