.landing-page-body,
.landing-page-content,
.landing-page-header,
.landing-page-popular,
.landing-page-top-categories,
.landing-page-popular > div,
.landing-page-top-categories > div,
.category-page-body,
.category-page-content,
.category-page-header,
.category-page-cards,
.category-page-cards > div,
.question-page-body,
.question-page-content,
.question-page-header,
.question-section,
.question-section > div,
.profile-page-body,
.profile-page-content,
.profile-page-header,
.profile-details,
.profile-detail-section,
.profile-detail-section-right,
.profile-overview-section,
.profile-overview,
.profile-achievement-title,
.profile-achievement-badges,
.total-achievements,
.leaderboard-page-body,
.leaderboard-page-content,
.leaderboard-page-header,
.leaderboard-table,
.leaderboard-user-details,
.leaderboard-user-details > div,
.help-page-body,
.help-page-content,
.help-page-header,
.notification-page-body,
.notification-page-content,
.notification-page-header,
.setting-page-body,
.setting-page-content,
.setting-page-header {
  display: flex;
  color: var(--title-color);
}

.landing-page-content,
.category-page-content,
.question-page-content,
.profile-page-content,
.leaderboard-page-content,
.help-page-content,
.notification-page-content,
.setting-page-content {
  max-width: 70rem;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 3rem 2rem;
}

.landing-page-content,
.category-page-content,
.question-page-content,
.question-section,
.profile-page-content,
.profile-details,
.profile-detail-section-right,
.leaderboard-page-content,
.leaderboard-table,
.help-page-content,
.notification-page-content,
.setting-page-content {
  flex-direction: column;
}

.landing-page-content,
.landing-page-header,
.category-page-header,
.category-page-content,
.question-page-header,
.profile-page-content,
.profile-page-header,
.profile-detail-section-right,
.profile-overview-section,
.profile-achievement-title,
.leaderboard-page-header,
.leaderboard-user-details,
.help-page-header,
.help-page-content,
.notification-page-header,
.notification-page-content,
.setting-page-header,
.setting-page-content {
  justify-content: space-between;
}

.landing-page-header,
.landing-page-popular > div,
.landing-page-top-categories > div,
.category-page-header,
.question-page-header,
.profile-overview,
.total-achievements,
.leaderboard-page-header,
.leaderboard-user-details,
.leaderboard-user-details > div,
.help-page-header,
.notification-page-header,
.setting-page-header {
  align-items: center;
}

.landing-page-header h1,
.category-page-header h1,
.question-page-header h1,
.profile-page-header h1,
.leaderboard-page-header h1,
.help-page-header h1,
.notification-page-header h1,
.setting-page-header h1 {
  font-size: 1.5rem;
  font-weight: 500;
}

.landing-page-popular,
.landing-page-top-categories,
.leaderboard-table-section,
.category-page-cards,
.help-page-title,
.all-notifications h2,
.setting-page-title {
  margin-top: 3.5rem;
}

.landing-page-popular,
.landing-page-top-categories,
.landing-page-popular h2,
.landing-page-top-categories h2,
.category-page-cards h2,
.question-section h2,
.leaderboard-table-section > h2,
.help-page-title,
.setting-page-title {
  font-weight: 500;
  font-size: 1.3rem;
}

@media screen and (max-width: 1024px) {
  .landing-page-content,
  .category-page-content,
  .question-page-content,
  .profile-page-content,
  .leaderboard-page-content,
  .help-page-content,
  .setting-page-content {
    padding: 2rem 1.5rem 1rem;
  }

  .landing-page-header h1,
  .category-page-header h1,
  .question-page-header h1,
  .profile-page-header h1,
  .leaderboard-page-header h1,
  .help-page-header h1,
  .setting-page-header h1 {
    font-size: 1.4rem;
  }

  .landing-page-popular h2,
  .landing-page-top-categories h2,
  .category-page-cards h2,
  .leaderboard-table-section > h2,
  .help-page-title,
  .setting-page-title {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .landing-page-content,
  .category-page-content,
  .question-page-content,
  .profile-page-content,
  .leaderboard-page-content,
  .help-page-content,
  .notification-page-content,
  .setting-page-content {
    width: 100%;
    padding: 2rem 1rem 1rem;
    margin-top: 2rem;
    margin-bottom: 3.5rem;
    min-height: 88vh;
  }

  .category-page-header,
  .category-page-cards > div,
  .question-page-header,
  .leaderboard-page-header,
  .help-page-header,
  .notification-page-header,
  .setting-page-header {
    justify-content: center;
  }

  .landing-page-header h1,
  .landing-page-popular h2,
  .landing-page-top-categories h2,
  .category-page-header h1,
  .category-page-cards h2,
  .question-page-header h1,
  .leaderboard-page-header h1,
  .leaderboard-table-section > h2,
  .help-page-header h1,
  .setting-page-header h1,
  .notification-page-header h1,
  .help-page-title,
  .setting-page-title {
    font-size: 1.2rem;
    margin-top: 10px;
  }

  .help-page-title,
  .setting-page-title {
    align-items: center;
  }
}

@media screen and (max-width: 400px) {
  .landing-page-header h1,
  .category-page-header h1,
  .question-page-header h1,
  .help-page-header h1,
  .notification-page-header h1,
  .setting-page-header h1 {
    font-size: 1.1rem;
  }
}
