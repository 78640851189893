:root {
  --nav-bar-bg: #065d8b;
  --background-color: #e5f7ff;
  --shadow-one: rgba(23, 23, 23, 0.235);
  --right-ans: rgb(180, 254, 180);
  --wrong-ans: rgb(255, 178, 178);
  --positive-score: rgba(2, 255, 36, 0.238);
  --zero-score: rgba(255, 9, 9, 0.276);
  --option-btn-shadow: hsla(201, 92%, 28%, 0.082);
  --progress-bar-bg: rgb(223, 223, 223);
  --progress-bar-color: var(--nav-bar-bg);
  --button-click: rgba(133, 133, 133, 0.461);
}

html[data-pebblequiz-theme="light"] {
  --nav-bar-bg: #065d8b;
  --background-color: #e5f7ff;
  --shadow-one: rgba(23, 23, 23, 0.235);
  --positive-score: rgb(1, 130, 18);
  --zero-score: rgb(255, 55, 55);
  --progress-bar-bg: rgb(223, 223, 223);
  --button-click: rgba(133, 133, 133, 0.461);
  --title-color: rgb(28, 28, 28);
  --sub-title-color: rgb(55, 55, 55);
  --rule-modal-bg: rgb(234, 234, 234);
  --answer-options: white;
  --notification-card: rgba(255, 255, 255, 0.837);
  --notification-shadow: rgba(0, 0, 0, 0.116);
}

html[data-pebblequiz-theme="dark"] {
  --nav-bar-bg: #1f1f1f;
  --background-color: #2b2b2b;
  --shadow-one: rgba(232, 232, 232, 0.235);
  --positive-score: rgb(73, 253, 97);
  --zero-score: rgb(255, 166, 166);
  --progress-bar-bg: rgb(223, 223, 223);
  --progress-bar-color: rgb(255, 132, 0);
  --title-color: rgb(231, 231, 231);
  --sub-title-color: rgb(202, 202, 202);
  --rule-modal-bg: #1c2224;
  --answer-options: rgb(93, 93, 93);
  --notification-card: rgba(42, 42, 42, 0.922);
  --notification-shadow: rgba(197, 197, 197, 0.229);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
