.nav-bar-body {
  width: 17rem;
  height: 100vh;
  padding: 2rem 0.8rem 1rem 1rem;

  background-color: var(--nav-bar-bg);
  color: var(--text-white);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0rem;
}

.nav-bar-body > a img {
  width: 10.5rem;
  display: flex;
  margin: 0 auto 10px;
}

.nav-bar-body a {
  color: var(--text-white);
}

.user-image-name {
  display: flex;
  align-items: center;
}

.user-image-name div {
  margin: 0 10px;
}

.user-image-name h2 {
  font-size: 1rem;
  font-weight: 500;
  width: 12rem;
  text-align: left;
}

.nav-bar-menu {
  margin-top: 3rem;
}

.nav-bar-menu div {
  display: flex;
  align-items: center;
  margin: 0.7rem 0;
  padding: 5px 10px;
}

.nav-bar-menu h2,
.theme-toggle-container h2 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-left: 1rem;
}

.nav-bar-menu a {
  text-decoration: none;
  color: var(--text-white);
}

.nav-bar-menu div:hover {
  outline: 1px solid var(--primary-light-color);
  transition: 0.3s ease-in;
  border-radius: 4px;
}

.theme-toggle-container {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.theme-toggle-container button {
  padding: 0;
}

.active-nav {
  outline: 1px solid var(--text-white);
  border-radius: 4px;
  transition: 0.3s ease-in;
}

@media screen and (max-width: 768px) {
  .nav-bar-body {
    display: none;
  }
}
