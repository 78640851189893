.category-page-cards {
  align-items: flex-start;
  flex-direction: column;
}

.category-page-cards > div {
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .category-page-cards {
    margin-top: 1rem;
    align-items: center;
  }
}

@media screen and (max-width: 400px) {
  .category-page-content {
    padding: 2rem 5px 1rem;
  }
}
