.leaderboard-table-section > h2 {
  text-align: left;
}

.leaderboard-table {
  align-items: flex-start;
  padding: 1rem 0;
}

.leaderboard-table ol {
  width: 100%;
  margin-left: 0;
  padding-right: 10px;
}

.leaderboard-table li {
  font-weight: 500;
  margin-left: 4px;
}

.leaderboard-table h2 {
  font-size: 1.1rem;
  font-weight: 500;
}

.leaderboard-user-details {
  width: 100%;
  margin: 0.8rem 0;
}

.leaderboard-user-details span {
  color: var(--title-color);
  margin-left: 8px;
}

.leaderboard-user-details img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-right: 2rem;
  object-fit: cover;
  object-position: top;
}

@media screen and (max-width: 768px) {
  .leaderboard-table-section {
    margin-top: 0;
  }

  .leaderboard-page-header h1 {
    display: none;
  }

  .leaderboard-page-header h1,
  .leaderboard-table-section > h2 {
    text-align: center;
    margin: 2rem 0 0;
  }
}

@media screen and (max-width: 450px) {
  .leaderboard-user-name {
    width: 10.4rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .leaderboard-table {
    padding: 8px 0;
  }
}
