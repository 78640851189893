.backdrop {
  backdrop-filter: blur(6px);
}

.quiz-end-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.quiz-end-modal .close-btn {
  position: absolute;
  top: 0.8rem;
  right: 1rem;
  padding: 8px 10px;
  border-radius: 50%;
  cursor: pointer;
}

.quiz-end-modal .close-btn:hover {
  background-color: var(--btn-hover);
}

.quiz-end-modal h1 {
  text-align: center;
}

.quiz-end-modal > div {
  width: 35rem;
  padding: 1.8rem 1.5rem;
  border-radius: 10px;
  position: relative;
}

.positive-score {
  background-color: var(--rule-modal-bg);
}

.positive-score h1 {
  color: var(--positive-score);
}

.zero-score {
  background-color: var(--rule-modal-bg);
}

.zero-score h1 {
  color: var(--zero-score);
}

.quiz-end-modal h1 {
  margin: 1rem 5px 1.5rem;
  font-size: 1.2rem;
  font-weight: 400;
}

.quiz-end-modal span {
  font-weight: bold;
}

.all-questions-container {
  overflow-y: scroll;
  height: 60vh;
  margin: 1rem 0 1rem;
  border-radius: 4px;
  border: 1px solid var(--sub-title-color);
}

.all-given-questions {
  margin: 10px 0;
  padding: 5px;
}

.end-modal-options {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.end-modal-options li {
  padding: 8px;
  width: 49%;
  border-radius: 4px;
  margin: auto;
}

.quiz-end-modal-cta {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding-left: 6px;
}

.quiz-end-modal-cta button {
  width: 97%;
  margin: 0;
}

@media screen and (max-width: 560px) {
  .quiz-end-modal > div {
    width: 100vw;
    height: 100vh;
    padding: 2rem 10px 1rem;
  }

  .all-questions-container {
    height: 60vh;
  }

  .quiz-end-modal h1 {
    font-size: 1.2rem;
    margin: 10px 5px 10px;
  }

  .end-modal-options li {
    width: 48%;
  }

  .quiz-end-modal-cta {
    display: grid;
    grid-template-columns: 1fr;
  }

  .quiz-end-modal-cta button {
    font-size: 1rem;
    width: 98%;
  }
}

@media screen and (max-width: 380px) {
  .quiz-end-modal > div {
    padding: 2rem 8px 1rem;
  }
}
