.loader-animation {
  background-color: rgba(0, 0, 0, 0.087);
}

.loader-animation,
.celebration-animation {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loader {
  width: 25rem;
  height: 100%;
}

#celebration {
  width: 100%;
  height: 100%;
}
