.App {
  background-color: var(--background-color);
  min-height: 95vh;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.447);
  width: 100%;
  height: 120vh;
  position: fixed;
  z-index: 3;
  margin-top: -4rem;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  color: var(--primary-color);
}

*::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  background-color: var(--primary-color);
  border-radius: 20px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

@media screen and (max-width: 768px) {
  .login-btn-desktop {
    display: none;
  }
}
