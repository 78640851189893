.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--title-color);
}

.page-header h1 {
  font-size: 1.5rem;
  font-weight: 500;
}

@media screen and (max-width: 1024px) {
  .page-header h1 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .page-header {
    justify-content: center;
  }

  .page-header h1 {
    font-size: 1.2rem;
    margin-top: 10px;
  }
}

@media screen and (max-width: 400px) {
  .page-header h1 {
    font-size: 1.1rem;
  }
}
