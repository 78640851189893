.profile-details {
  height: 100%;
}

.profile-page-header {
  align-items: center;
}

.profile-page-header > div {
  justify-content: flex-end;
}

.profile-detail-section {
  margin: 2rem 0;
}

.profile-detail-img {
  position: relative;
}

.profile-detail-img img {
  width: 12rem;
  height: 12rem;
  margin-right: 1rem;
  border-radius: 10px;
}

.edit-photo {
  padding: 1rem;
  border-radius: 50%;
  position: absolute;
  padding: 10px;
  right: 15px;
  bottom: 0px;
  font-size: 1.2rem;
}

.profile-detail-section-right {
  align-items: flex-start;
  width: 100%;
  padding: 0 1rem;
}

.user-detail-overview {
  text-align: left;
  margin-bottom: 1rem;
}

.profile-overview div {
  text-align: left;
  padding: 0 10px;
}

.profile-overview h3 {
  font-size: 1rem;
}

.profile-overview-section {
  width: 95%;
}

.profile-overview img {
  width: 3rem;
}

.achievements-progress-bar {
  margin-left: 10px;
  height: 6px;
  border-radius: 30px;
  width: 12rem;
  background-color: var(--progress-bar-bg);
}

.achievements-progress-bar-fill {
  height: 6px;
  background-color: var(--progress-bar-color);
  border-radius: 30px;
}

.profile-achievement-badges {
  gap: 1.8rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 2rem;
  margin: 1.2rem 0;
  border: 1px solid var(--title-color);
  border-radius: 15px;
}

.profile-achievement-badges img {
  width: 6.9rem;
  height: 5.8rem;
}

.not-logged-message {
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .profile-detail-section > img {
    width: 10rem;
    height: 10rem;
    margin-right: 10px;
  }

  .profile-detail-section-right {
    padding: 0;
  }

  .profile-overview div {
    text-align: left;
    padding: 0 6px;
  }

  .profile-overview-section {
    width: 100%;
    flex-wrap: wrap;
  }

  .profile-overview img {
    width: 2.3rem;
  }

  .profile-achievement-title {
    justify-content: space-between;
  }

  .profile-achievement-badges {
    gap: 1.2rem;
  }

  .profile-achievement-badges img {
    width: 5.5rem;
    height: 5rem;
  }
}

@media screen and (max-width: 768px) {
  .profile-page-header h1 {
    font-size: 1.2rem;
    text-align: left;
    margin-top: 10px;
  }

  .profile-detail-section > img {
    width: 10rem;
    height: 10rem;
    margin-right: 1rem;
  }

  .profile-overview img {
    width: 2.5rem;
  }

  .profile-achievement-badges {
    gap: 1.4rem;
  }

  .profile-achievement-badges img {
    width: 5.7rem;
    height: 4.5rem;
  }
}

@media screen and (max-width: 450px) {
  .profile-page-content {
    padding: 2rem 5px 1rem;
  }

  .profile-page-header h1 {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .profile-detail-section > img {
    width: 8rem;
    height: 8rem;
    margin-right: 10px;
  }

  .user-detail-overview h2 {
    font-size: 1.2rem;
  }

  .profile-overview {
    margin: 3px 0;
  }

  .profile-overview img {
    display: none;
  }

  .profile-achievement-badges {
    gap: 10px;
    padding: 10px;
    justify-content: center;
  }

  .profile-achievement-badges img {
    width: 4.8rem;
    height: 4.5rem;
  }
}
