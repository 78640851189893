.question-page-header {
  width: 100%;
}

.question-page-content .login-btn-desktop {
  justify-content: flex-end;
}

.question-section {
  align-items: flex-start;
  margin-top: 2rem;
}

.error-loading-message {
  font-size: 1.2rem;
  margin: auto;
}

.question-section > div {
  flex-wrap: wrap;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .question-page-cards {
    margin-top: 1rem;
    align-items: center;
  }
}

@media screen and (max-width: 400px) {
  .category-page-content {
    padding: 2rem 5px 1rem;
  }
}
