.signup-modal-one {
  z-index: 4;
}

.signup-modal-one .close-modal-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.signup-modal-one h1 {
  margin: 0;
}

.existing-account-btn {
  text-align: center;
  cursor: pointer;
}

.existing-account-btn h2 {
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 10px;
}

.existing-account-btn span {
  padding: 0 10px;
  font-weight: 500;
  color: var(--primary-color);
}

@media screen and (max-width: 400px) {
  .signup-modal-one,
  .signup-modal-one form {
    width: 100%;
  }
}
