.reset-password-container {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 1.2rem;
  z-index: 4;
  backdrop-filter: blur(4px) brightness(40%);
}

.reset-password-container > div {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--rule-modal-bg);
  padding: 2rem 1rem 1.5rem;
  border-radius: 5px;
  width: 22rem;
}

.reset-password-container .close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.no-outline-email-input {
  width: 90%;
}

.no-outline-email-input input,
.no-outline-email-input label {
  color: var(--title-color);
}
