.category-card {
  margin: 1rem 2rem 1rem 0;
  border-radius: 10px;
}

.category-card-image {
  border-radius: 10px;
}

.card-square,
.card-square .category-card-image,
.card-small,
.card-small .category-card-image {
  width: 19rem;
}

.card-square {
  height: 18.5rem;
}

.card-square .category-card-image {
  height: 15.5rem;
  object-fit: cover;
}

.card-small {
  height: 13rem;
}

.card-small .category-card-image {
  height: 10rem;
  object-fit: cover;
}

.popular-card-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.card-title {
  font-size: 1.1rem;
  margin-top: 6px;
  text-align: center;
  color: var(--title-color);
}

@media screen and (max-width: 1024px) {
  .card-square,
  .card-square .category-card-image,
  .card-small,
  .card-small .category-card-image {
    width: 16rem;
  }
}

@media screen and (max-width: 920px) {
  .card-square,
  .card-square .category-card-image,
  .card-small,
  .card-small .category-card-image {
    width: 13.2rem;
  }
}

@media screen and (max-width: 768px) {
  .category-card {
    margin: 1rem 1rem 1rem 0;
  }

  .card-square,
  .card-square .category-card-image,
  .card-small,
  .card-small .category-card-image {
    width: 13rem;
  }
}

@media screen and (max-width: 500px) {
  .category-card {
    margin: 1rem 1rem 1rem 0;
  }

  .card-square,
  .card-square .category-card-image,
  .card-small,
  .card-small .category-card-image {
    width: 10rem;
  }

  .popular-card-section {
    padding: 0 5px;
    margin: auto;
  }

  .card-title {
    font-size: 1rem;
    margin-top: 6px;
    text-align: center;
  }
}

@media screen and (max-width: 400px) {
  .category-card {
    margin: 1rem 1rem 1rem 0;
  }

  .card-square,
  .card-square .category-card-image,
  .card-small,
  .card-small .category-card-image {
    width: 16rem;
  }
}
