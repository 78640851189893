.badge-modal-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  backdrop-filter: blur(5px) brightness(40%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.badge-modal-container div {
  background-color: var(--rule-modal-bg);
  width: 30rem;
  padding: 2.5rem 1rem;
  border-radius: 10px;
  color: var(--title-color);
}
