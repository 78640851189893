.not-found-page-body {
  width: 100%;
  max-width: 70rem;
  min-height: 100vh;
  margin: 0 auto;
}

.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}

.page-not-found h2 {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 1rem 0;
}

.error-number {
  display: flex;
  margin: 4rem auto 0;
}

.error-number h1:nth-child(1) {
  transform: rotate(30deg);
}

.error-number h1:nth-child(3) {
  transform: rotate(80deg);
  margin-top: 4rem;
  font-size: 8rem;
}

.page-not-found h1 {
  font-size: 6rem;
  letter-spacing: 2rem;
}
